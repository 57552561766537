/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Input, Modal } from 'antd';
import { AudioOutlined, StopOutlined } from '@ant-design/icons';
import './style.scss';
import Utils from '../../redux/utils';
import ResponseDialog from '../ResopnseDialog';
import HelpApi from '../../redux/middleware/help';

type Props = {
  handleDialog: any;
  open: boolean;
  component?: any;
};

const HelpModal = (props: Props) => {
  const company = Utils.getCurrentCompany();
  const brand = Utils.getCurrentBrand();
  let { handleDialog, open, component } = props;

  const [inputValue, setInputValue] = useState<string>('');
  const [errorList, setErrorList] = useState<any>({});
  const [errorStatus, setErrorStatus] = useState<boolean>(false);
  const [isListening, setIsListening] = useState<boolean>(false);
  const [transcription, setTranscription] = useState<any>();
  const [response, setResponse] = useState<any>();

  useEffect(() => {
    if (!('webkitSpeechRecognition' in window)) {
      let responseDialogData = {
        OnClick: () => {},
        type: 'error',
        messageArray: [`Speech recognition is not supported in this browser.`],
      };
      ResponseDialog(responseDialogData);
      return;
    }

    const recognition = new (window as any).webkitSpeechRecognition();
    recognition.continuous = false;
    recognition.lang = 'en-US';
    recognition.interimResults = false;
    recognition.onstart = () => setIsListening(true);
    recognition.onend = () => setIsListening(false);
    recognition.onerror = (e: any) => {
      console.error('Speech recognition error:', e);
      setIsListening(false);
    };
    recognition.onresult = (e: any) => {
      const text = e.results[0][0].transcript;
      setTranscription(text);
      setInputValue(text);
    };

    return () => {
      recognition.stop();
    };
  }, []);

  const toggleListening = () => {
    const recognition = new (window as any).webkitSpeechRecognition();
    if (isListening) {
      recognition.stop();
    } else {
      recognition.start();
    }
  };

  const handleSubmit = async () => {
    try {
      const query = inputValue || transcription;
      if (!query) {
        setErrorStatus(true);
        setErrorList({ help: ['Please enter a query or use speech.'] });
        return;
      }
      const apiResponse = await HelpApi.getHelpResponse(query);
      const formattedSteps = apiResponse?.guide_steps[0]
        .split('\n')
        .map((step: any) => step.trim())
        .filter((step: any) => step && !step.toLowerCase().includes('steps:'));
      setResponse(formattedSteps);
      console.log(formattedSteps);
      setErrorStatus(false);
      setErrorList({});
    } catch (error) {
      console.error('Error submitting query:', error);
      setErrorStatus(true);
      setErrorList({
        help: ['Failed to get a response. Please try again.'],
      });
    }
  };

  return (
    <div className="CategoryModal">
      <Modal
        title={<div className="title font">Help</div>}
        centered
        open={open}
        onCancel={() => handleDialog(false, {})}
        width={608}
        footer={null}
      >
        <div className="Modal">
          <div className="ingredientCategoryContainerWidth">
            <div className="wizardSubContainer help">
              <div className="CategoryModalwizardLabel">
                Ask for Help <span className="sterik">*</span>
              </div>
              <div className="inputContainer">
                <Input
                  value={inputValue || transcription}
                  placeholder="Type your query or use speech..."
                  size="large"
                  onChange={(e) => setInputValue(e.target.value)}
                  required
                  className={
                    errorList['help']?.length > 0 ? 'dropdownUnitColorRed' : ''
                  }
                  addonBefore={
                    <button
                      onClick={toggleListening}
                      style={{ background: 'none', border: 'none' }}
                    >
                      {isListening ? <StopOutlined /> : <AudioOutlined />}
                    </button>
                  }
                />
              </div>
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['help']}
                </div>
              )}
            </div>
          </div>
          {response && (
            <>
              <h3 className="steps-heading">Steps:</h3>
              <div className="responseContainer">
                <ol>
                  {response.map((item: any, index: number) => (
                    <li key={index}>{item}</li>
                  ))}
                </ol>
              </div>
            </>
          )}
          <div className="butonGroup">
            <div
              className="wizard-button"
              onClick={() => handleDialog(false, {})}
            >
              Back
            </div>
            <div className="continue wizard-button" onClick={handleSubmit}>
              Submit
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default HelpModal;
