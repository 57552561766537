import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Spin } from 'antd';
import {
  Page,
  Stepper,
  UserWizardStepOne,
  UserWizardStepTwo,
  ResponseDialog,
} from '../../components';
import UsersApi from '../../redux/middleware/users';
import './styles.scss';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Utils from '../../redux/utils';
import { checkAuthentication } from '../../helper/checkAuthentication';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

type Props = {
  update: boolean;
  id: string | number;
  bypassPermission?: boolean;
  onSetupSuccess?: any;
  component?: string;
};

const UserWizardForm = (props: Props) => {
  const navigate = useNavigate();
  const { update, id, bypassPermission, onSetupSuccess, component } = props;
  const [visible, setVisible] = useState(false);
  const [permissions, setPermissions] = useState<any[]>([]);
  const [roleData, setRoleData] = useState<any[]>([]);
  const [designationData, setDesignationData] = useState<any[]>([]);
  const [companyData, setCompanyData] = useState<any[]>([]);
  const [defaultCompanies, setDefaultCompanies] = useState<any[]>([]);
  const [brandsData, setBrandsData] = useState<any[]>([]);
  const [branchesData, setBranchesData] = useState<any[]>([]);
  const [branchesDataForRole2, setBranchesDataForRole2] = useState<any[]>([]);
  const [userRole, setUserRole] = useState<any>({});
  const [designation, setDesignation] = useState<any[]>([]);
  const [company, setCompany] = useState<any>({});
  const [brandId, setBrandId] = useState<any>();
  const [branchId, setBranchId] = useState<any>();
  const [hidePasswordField, setHidePasswordField] = useState(true);
  const [branchIdForRole2, setBranchIdForRole2] = useState<any>();
  const [name, setName] = useState('');
  const [toggleField, setToggleField] = useState(false);
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [checkBox, setCheckBox] = useState<any[]>([]);
  const [toggleSwitch, setToggleSwitch] = useState<any[]>([]);
  const user = Utils.getCurrentUser();
  const [apiLoader, setApiLoader] = useState(false);
  const [countryCode, setCountryCode] = useState<any>(
    user?.country_code || '+971'
  );
  const companyObject = Utils.getCurrentCompany();
  const role_id = user?.roles[0]?.id;
  const [stepper, setStepper] = useState(0);
  const [loader, setLoader] = useState(false);
  const [categoryData] = useState([
    { value: 'Appetizer', label: 'Appetizer', key: 'Appetizer' },
    { value: 'Hi-Tea', label: 'Hi-Tea', key: 'Hi-Tea' },
    { value: 'Lunch', label: 'Lunch', key: 'Lunch' },
  ]);

  const [errorList, setErrorList] = useState<any>({});
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [errorMessage] = useState<any[]>([]);
  const [error, setError] = useState(false);
  const { currentBrand, currentShop } = useSelector((state: any) => state.Auth);
  const [currentBrandId] = useState(currentBrand?.brand_id);
  const [verified, setVerified] = useState(0);

  const { t } = useTranslation('translation', {
    keyPrefix: 'users',
  });

  useEffect(() => {
    if (update && currentBrandId !== currentBrand?.brand_id) {
      navigate('/app/users');
    }
  }, [currentBrand, currentShop]);

  React.useEffect(() => {
    checkAuthentication(user, navigate);
  }, []);

  React.useEffect(() => {
    if (!update && permissions?.length) {
      const e = {
        target: {
          checked: true,
        },
      };
      menuHandler(e as CheckboxChangeEvent);
    }
  }, [permissions]);

  React.useEffect(() => {
    if (
      !(
        userRole &&
        Object.keys(userRole).length === 0 &&
        Object.getPrototypeOf(userRole) === Object.prototype
      )
    ) {
      // if (userRole) {
      //   console.log('userRole', userRole, role_id, designationData);
      // }
      if (userRole?.value === 5) {
        setDesignation(
          designationData?.filter(
            (designationObj: any) => designationObj?.label === userRole?.label
          )
        );
        setToggleField(true);
        fetchUserBrands();
        setBranchesData([]);
      } else {
        fetchUserBranches();
        if (role_id == 3) {
          setDesignation(
            designationData?.filter(
              (designationObj: any) => designationObj?.label === userRole?.label
            )
          );
          setBrandsData([]);
        }
      }
      setBrandId([]);
      setBranchId([]);
    }
  }, [userRole]);

  useEffect(() => {
    setDesignation(
      designationData?.filter(
        (designationObj: any) => designationObj?.label === userRole?.label
      )
    );
  }, [designationData]);

  const handleTogglePasswordField = () => {
    setHidePasswordField(!hidePasswordField);
  };

  const handleEmail = (data: any) => {
    setEmail(data);
  };

  const handlePassword = (data: any) => {
    setPassword(data);
  };

  const handleName = (data: any) => {
    setName(data);
  };

  const handlePhone = (data: any) => {
    const phoneRegex = /^\+?[0-9]+$/;
    if (data.trim() === '') {
      setErrorList({ ...errorList, phone: '' });
      setErrorStatus(false);
    } else if (!phoneRegex.test(data.trim())) {
      setErrorList({ ...errorList, phone: 'Invalid phone number' });
      setErrorStatus(true);
    } else {
      setErrorList({ ...errorList, phone: '' });
      setErrorStatus(false);
    }
    setPhone(data.trim());
  };

  const handleChangeRole = (e: any) => {
    setToggleField(false);
    setUserRole(roleData?.find((data: any) => data?.value == e));
    // setUserRole(e);
    fetchUserPermissions(e);
  };

  const handleChangeDesgnation = (e: any) => {
    const data: any = e.map((data: any) => {
      return designationData.filter((id: any) => {
        if (id.value == data) {
          return id;
        }
      });
    });
    setDesignation(data.flat());
  };

  const handleBranchId = (id: any) => {
    const uniqueIds = Array.from(new Set([...id]));
    setBranchId(uniqueIds);
  };

  const handleBranchIdForRole2 = (id: any) => {
    const uniqueIds = Array.from(new Set([...id]));
    setBranchIdForRole2(uniqueIds);
  };

  const menuHandler = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      let arr = permissions
        ?.map((permission: any) =>
          permission?.permissions?.length
            ? permission?.permissions?.map((child: any) => child?.id)
            : []
        )
        ?.flat();

      // let arr = permissions.map((data) => data?.id);
      setToggleSwitch([...arr]);
    } else {
      setToggleSwitch([]);
    }
  };

  const handleCheckbox = (e: CheckboxChangeEvent, val: any) => {
    e.target.checked
      ? setCheckBox((prev: any) => {
          return [...prev, val?.id];
        })
      : setCheckBox((prev) => {
          return prev.filter((data) => data != val?.id);
        });
  };

  const handleSwitch = (checked: boolean, val: any) => {
    checked
      ? setToggleSwitch((prev: any) => {
          return [...prev, val?.id];
        })
      : setToggleSwitch((prev) => {
          return prev.filter((data) => data != val?.id);
        });
  };
  const fetchUserRoles = async () => {
    const userRoleData = await UsersApi.getUserRoles();
    setRoleData(
      userRoleData?.data?.items?.map((data: any) => {
        return {
          label: data?.name,
          value: data?.id,
          key: data?.guard_name,
        };
      })
    );
    setUserRole({
      label: userRoleData?.data?.items[0]?.name,
      value: userRoleData?.data?.items[0]?.id,
      key: userRoleData?.data?.items[0]?.guard_name,
    });

    fetchUserPermissions(userRoleData?.data?.items[0]?.id);
  };
  const fetchUserDesignation = async () => {
    const userDesignationData = await UsersApi.getUserDesignation();
    setDesignationData(
      userDesignationData?.data?.items?.map((data: any) => {
        return {
          label: data?.designation,
          value: data?.id,
        };
      })
    );
  };
  const fetchUserCompany = async () => {
    const userCompanyData = await UsersApi.getUserCompany();
    setDefaultCompanies(userCompanyData?.data?.items);
    setCompanyData(
      userCompanyData?.data?.items?.map((data: any) => {
        return {
          label: data?.company_name,
          value: data?.id,
        };
      })
    );
  };
  const fetchUserBrands = async () => {
    let id = company;
    role_id === 1 ? id : companyObject?.id;
    const userBrandsData = await UsersApi.getUserBrands(
      role_id === 1 ? id : companyObject?.id
    );

    setBrandsData(
      userBrandsData?.data?.items?.map((data: any) => ({
        label: data?.name,
        value: data?.brand_id,
      }))
    );
    // if (user?.roles[0]?.name == 'Company Owner' && !update) {
    //   setBrandId(
    //     userBrandsData?.data?.items?.map((data: any) => data?.brand_id)
    //   );
    // }
  };
  const fetchUserBranches = async () => {
    const userBranchesData = await UsersApi.getUserBranches();
    // setAllBrands(userBranchesData?.data?.items);
    setBranchesData(
      userBranchesData?.data?.items?.map((data: any) => {
        return {
          label: data?.shop_name,
          value: data?.id,
        };
      })
    );
  };

  const fetchBrandBranch = async (id: any) => {
    if (id?.length !== 0) {
      const response = await UsersApi.getShopBrands(id.toString(), 'brand_id');
      // setBranchesDataForRole2(
      //   response?.data?.map((data: any) => {
      //     return {
      //       label: data?.shop_name,
      //       value: data?.id,
      //     };
      //   })
      // );
      setBranchesDataForRole2(
        response?.data
          .reduce((uniqueData: any, data: any) => {
            const index = uniqueData.findIndex(
              (item: any) => item.id === data.id
            );
            if (index === -1) {
              uniqueData.push(data);
            }
            return uniqueData;
          }, [])
          .map((data: any) => ({
            label: data?.shop_name,
            value: data?.id,
          }))
      );
      if (user?.roles[0]?.name == 'Company Owner' && !update) {
        // setBranchIdForRole2(response?.data?.map((data: any) => data?.id));
        setBranchIdForRole2(
          response?.data.reduce((uniqueIds: any, data: any) => {
            if (!uniqueIds.includes(data.id)) {
              uniqueIds.push(data.id);
            }
            return uniqueIds;
          }, [])
        );
      }
    }
  };

  const fetchUserPermissions = async (role: any) => {
    const userPermissionData = await UsersApi.getUserPermissions(role);
    setPermissions(userPermissionData?.data?.items);
  };

  const fetchShopBrands = async (label: string) => {
    // return shopBrands?.data?.map((data: any) => {
    //   return {
    //     label: data?.brand_name,
    //     value: data?.brand_id,
    //   };
    // });

    // Promise.all(response).then(function (results) {
    //   // remove duplicate objects from brand array
    //   let brands = results
    //     ?.flat()
    //     .filter(
    //       (brand, index, self) =>
    //         index ===
    //         self.findIndex(
    //           (specificBrand) =>
    //             specificBrand?.label === brand?.label &&
    //             specificBrand?.value === brand?.value
    //         )
    //     );
    //   //set brands when remove branches for branch manager role

    //   // const brandData = brandId?.filter((data: any) => {
    //   //   return (
    //   //     brands?.find((brandArray: any) => brandArray?.value == data)?.value !=
    //   //     undefined
    //   //   );
    //   // });
    //   setBrandId(
    //     brands?.find((brandArray: any) => brandArray?.value == brandId)?.value
    //   );

    //   setBrandsData(brands);
    // });
    if (userRole?.value == 4) {
      const response = await UsersApi.getShopBrands(
        branchId?.toString(),
        label
      );
      response?.data?.map((data: any) => {
        return {
          label: data?.brand_name,
          value: data?.brand_id,
        };
      });

      !update && setBrandId(response?.data?.map((data: any) => data?.brand_id));

      setBrandsData(
        response?.data?.map((data: any) => {
          return {
            label: data?.brand_name,
            value: data?.brand_id,
          };
        })
      );
    } else {
      const response = await UsersApi.getShopBrands(brandId?.toString(), label);

      // response?.data?.map((data: any) => {
      //   return {
      //     label: data?.brand_name,
      //     value: data?.brand_id,
      //   };
      // });

      !update &&
        setBranchId([...new Set(response?.data?.map((data: any) => data?.id))]);
      setBranchesData(
        response?.data
          .reduce((uniqueData: any, data: any) => {
            const index = uniqueData.findIndex(
              (item: any) => item.id === data.id
            );
            if (index === -1) {
              uniqueData.push(data);
            }
            return uniqueData;
          }, [])
          .map((data: any) => ({
            label: data?.shop_name,
            value: data?.id,
          }))
      );
      // setBranchesData(
      //   response?.data?.map((data: any) => {
      //     return {
      //       label: data?.shop_name,
      //       value: data?.id,
      //     };
      //   })
      // );
    }
  };

  const fetchDataById = async (id: any) => {
    const data = await UsersApi.getUserById(id);

    if (data) {
      setLoader(false);
    }
    setHidePasswordField(!update);
    setUserRole(
      data?.data?.roles?.length && {
        label: data?.data?.roles[0]?.name,
        value: data?.data?.roles[0]?.id,
        key: data?.data?.roles[0]?.guard_name,
      }
    );
    setVerified(data?.data?.is_email_verified);
    fetchUserPermissions(data?.data?.roles && data?.data?.roles[0]?.id);
    setBranchesDataForRole2(
      data?.data?.company_tree[0]?.shops?.map((data: any) => {
        return {
          label: data?.shop_name,
          value: data?.shop_id,
        };
      })
    );
    setDesignation(
      data?.data?.designation?.map((data: any) => {
        return {
          ...data,
          label: data?.designation,
          value: data?.designation_id,
        };
      })
    );
    setCompany(
      data?.data?.company_tree?.map((data: any) => {
        return {
          ...data,

          label: data?.company_name,
          value: parseInt(data?.id),
        };
      })[0]
    );

    if (role_id == 2) {
      setBrandId(
        data?.data?.company_tree[0]?.brands?.map((data: any) => data?.brand_id)
      );
      fetchBrandBranch(
        data?.data?.company_tree[0]?.brands?.map((data: any) => data?.brand_id)
      );
    } else
      setBrandId(
        data?.data?.company_tree[0]?.brands?.map((data: any) => data?.brand_id)
      );

    role_id !== 2
      ? setBranchId(
          Array.from(
            new Set(
              data?.data?.company_tree[0]?.shops?.map(
                (data: any) => data?.shop_id
              )
            )
          )
        )
      : setBranchIdForRole2(
          Array.from(
            new Set(
              data?.data?.company_tree[0]?.shops?.map(
                (data: any) => data?.shop_id
              )
            )
          )
        );

    setEmail(data?.data?.email);
    setName(data?.data?.name);
    setPhone(data?.data?.phone);

    setToggleSwitch(
      data?.data?.permissions.map((data: any) => {
        return data?.permission_id;
      })
    );

    setCheckBox(
      data?.data?.permissions
        .map((data: any) => {
          if (data?.perm_create == 1) return data?.permission_id;
        })
        ?.filter((data: any) => data != null)
    );
  };

  useEffect(() => {
    fetchUserRoles();
    fetchUserDesignation();
    fetchUserCompany();
    // if (role_id !== 2 && role_id !== 1) {
    //   fetchUserBranches();
    // }
    role_id == 2 && fetchUserBrands();
    if (update) {
      setLoader(true);
      fetchDataById(id);
    }
  }, []);

  useEffect(() => {
    if (userRole?.value == 4)
      if (branchId?.length > 0) {
        // branchId?.map((data: any) => fetchShopBrands(data))
        fetchShopBrands('shop_id');
      } else {
        setBrandsData([]);
        setBrandId(null);
      }
  }, [branchId]);

  useEffect(() => {
    if (userRole?.value == 5)
      if (brandId?.length > 0) {
        // brandId?.map((data: any) => fetchShopBrands(data));
        fetchShopBrands('brand_id');
      } else {
        setBranchesData([]);
        setBranchId(null);
      }
  }, [brandId]);

  useEffect(() => {
    if (designationData?.length && role_id == 1) {
      setDesignation(
        designationData?.filter(
          (data: any) => data?.label?.toLowerCase() == 'company owner'
        )
      );
    }
  }, [designationData]);

  const handleCompany = (value: any) => {
    setCompany(value);
    setCountryCode(
      defaultCompanies?.find((data: any) => data?.id == value)?.country_code
    );
  };
  const handleBrand = (value: any) => {
    // const data: any = brandsData.filter((data: any) => data?.value == value);
    // if (userRole?.value == 5) {
    // }
    setBrandId(value);
    fetchBrandBranch(value);
  };

  const handleBack = () => {
    if (stepper > 0) {
      setVisible(false);
      setError(false);
      setStepper(stepper - 1);
    } else {
      navigate('/app/users');
      setVisible(false);
      setError(false);
      setStepper(stepper);
    }
  };

  const updateInfo = async () => {
    setErrorList({});
    setErrorStatus(false);
    const params: any = {
      user_type:
        component === 'user-profile'
          ? user?.roles[0]?.id
          : component === 'user-wizard'
          ? userRole?.value
          : userRole?.value,
      designation: designation.map((data: any) => {
        return data?.value?.toString() || data?.designation_id?.toString();
      }),
      company_id: company?.value || company,
      brand_id: !brandId ? [0] : handlePayloadForBrand(),
      shop_id: handlePayloadForShop(),
      name: name,
      phone: phone,
      email: email,
      password: password,
      perm_create: checkBox,
      permissions: toggleSwitch,
    };
    let responseDialogData;
    setApiLoader(true);

    const response = await UsersApi.updateUser(params, id);
    setApiLoader(false);
    if (!response?.success) {
      setErrorList(response?.error);
      setErrorStatus(true);
      setStepper(0);
      responseDialogData = {
        OnClick: () => {
          setStepper(0);
        },
        type: 'error',
        messageArray: [
          'Validation Failed!',
          `${response?.error?.email || response?.error?.name}`,
        ],
      };
      ResponseDialog(responseDialogData);
    } else if (bypassPermission) {
      onSetupSuccess('2');
    } else {
      navigate('/app/users');
    }
  };

  const handleContinue = async (index: any) => {
    if (bypassPermission) {
      updateInfo();
    } else {
      if (update) {
        if (
          (stepper === 0 &&
            (userRole === undefined ||
              company === undefined ||
              designation === undefined ||
              name === '' ||
              email === '')) ||
          (stepper === 1 && toggleSwitch?.length === 0)
        ) {
          setVisible(true);
          setError(false);
          window.scrollTo(0, 0);

          return;
        }
      } else {
        if (
          (stepper === 0 &&
            (userRole === undefined ||
              company === undefined ||
              designation === undefined ||
              (role_id != 1 && role_id != 2 && !branchId) ||
              (role_id != 1 && !brandId) ||
              (role_id != 1 && !designation?.length) ||
              name === '' ||
              email === '')) ||
          // password === '' ||
          // password?.length < 10)) ||
          (stepper === 1 && toggleSwitch?.length === 0)
        ) {
          setVisible(true);
          setError(false);
          window.scrollTo(0, 0);

          return;
        }
      }
      if (stepper < 2) {
        setVisible(false);
        setError(false);
        setStepper(index);
      } else {
        setVisible(false);
        setError(false);
        setStepper(stepper);
      }
      if (user && stepper == 1 && !id) {
        setErrorList({});
        setErrorStatus(false);
        const params = {
          user_type:
            component === 'user-profile'
              ? user?.roles[0]?.id
              : component === 'user-wizard'
              ? userRole?.value
              : userRole?.value,
          designation: designation.map((data: any) => {
            return data?.value?.toString() || data?.designation_id?.toString();
          }),
          company_id: company,
          brand_id: !brandId ? [0] : handlePayloadForBrand(),
          shop_id: handlePayloadForShop(),
          name: name,
          phone: phone,
          email: email,
          password: '0000000000',
          perm_create: checkBox,
          permissions: toggleSwitch,
        };
        let responseDialogData;
        setApiLoader(true);

        try {
          const response = await UsersApi.addUsers(params);

          setApiLoader(false);
          if (!response?.success) {
            if (response !== undefined) {
              setErrorList(response?.error);
              setErrorStatus(true);
            }
            responseDialogData = {
              OnClick: () => {
                setStepper(0);
              },
              type: 'error',
              messageArray: [
                'Validation Failed!',
                `${
                  response?.error?.email ||
                  response?.error?.name ||
                  response?.error?.phone
                }`,
              ],
            };
            ResponseDialog(responseDialogData);
          } else {
            navigate('/app/users');
          }
        } catch (e) {
          console.log(e);
        }
      } else if (update && stepper == 1) {
        updateInfo();
      }
    }
  };

  const handlePayloadForBrand = () => {
    switch (role_id) {
      case 1:
        return brandId;
      case 2:
        return brandId;
      case 3:
        return handleUserRoleForBrand();
      default:
        return brandId;
    }
  };

  const handlePayloadForShop = () => {
    switch (role_id) {
      case 1:
        return branchId;
      case 2:
        return branchIdForRole2;
      case 3:
        // return handleUserRoleForShop();
        return branchId;
      default:
        return branchId;
    }
  };

  const handleUserRoleForBrand = () => {
    if (userRole?.value == 4) {
      return brandId;
    } else {
      return brandId;
    }
  };

  const handleStepper = (value: number) => {
    if (value > stepper) handleContinue(value);
    else setStepper(value);
  };

  return (
    <>
      <div className="alertMessage">
        {visible && (
          <Alert
            message="Error"
            description="Please complete all required fields to proceed"
            type="error"
            showIcon
            closable
            className="wizardAlert"
            afterClose={() => setVisible(false)}
          />
        )}
        {error &&
          errorMessage?.map((data: any, index: any) => (
            <Alert
              message="Error"
              description={data}
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setError(false)}
              key={index}
            />
          ))}
      </div>
      <Spin
        size="large"
        className="loadIngredient recipeBuilderloaderHeight"
        spinning={apiLoader}
      >
        <Page
          title={t('add_new_user')}
          screenName={bypassPermission ? 'setupProfile' : ''}
        >
          <Stepper
            stepper={stepper}
            component={'user'}
            onStepperChange={handleStepper}
          />

          {loader ? (
            <Spin
              spinning={loader}
              size="large"
              className="loadIngredient recipeBuilderloaderHeight"
            />
          ) : (
            <div className="userListWizardContainer">
              {stepper === 0 ? (
                <UserWizardStepOne
                  hidePasswordField={hidePasswordField}
                  handleTogglePasswordField={handleTogglePasswordField}
                  categoryData={categoryData}
                  roleData={roleData}
                  designationData={designationData}
                  companyData={companyData}
                  brandsData={brandsData}
                  branchesData={branchesData}
                  branchesDataForRole2={branchesDataForRole2}
                  branchIdForRole2={branchIdForRole2}
                  userRole={userRole}
                  designation={designation}
                  company={company}
                  branchId={branchId}
                  toggleField={toggleField}
                  handleBranchIdForRole2={handleBranchIdForRole2}
                  name={name}
                  phone={phone}
                  email={email}
                  brandId={brandId}
                  password={password}
                  handlePassword={handlePassword}
                  handleEmail={handleEmail}
                  handlePhone={handlePhone}
                  handleName={handleName}
                  handleBranchId={handleBranchId}
                  handleCompany={handleCompany}
                  handleBrand={handleBrand}
                  handleChangeRole={handleChangeRole}
                  handleChangeDesgnation={handleChangeDesgnation}
                  countryCode={countryCode}
                  errorList={errorList}
                  errorStatus={errorStatus}
                  verified={verified}
                  isConfigurationForm={bypassPermission}
                  update={update}
                />
              ) : (
                <UserWizardStepTwo
                  toggleSwitch={toggleSwitch}
                  checkBox={checkBox}
                  menuHandler={menuHandler}
                  handleCheckbox={handleCheckbox}
                  handleSwitch={handleSwitch}
                  permissionList={permissions}
                />
              )}

              <div
                className="butonGroup"
                style={
                  stepper === 0 ? { margin: 'auto' } : { marginLeft: 'auto' }
                }
              >
                {!bypassPermission && (
                  <div className="wizard-button" onClick={() => handleBack()}>
                    {t('back')}
                  </div>
                )}
                <div
                  className="continue wizard-button"
                  onClick={() => handleContinue(stepper + 1)}
                >
                  {bypassPermission ? 'Save changes' : t('continue')}
                </div>
              </div>
            </div>
          )}
        </Page>
      </Spin>
    </>
  );
};

export default UserWizardForm;
