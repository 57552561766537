/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import HelpModal from '../HelpModal';

const FloatingIcon = () => {
  const [openDialog, setOpenDialog] = useState(false);

  const handlemodal = (toggle: boolean, data: any) => {
    setOpenDialog(toggle);
  };

  return (
    <>
      {openDialog ? (
        <HelpModal
          handleDialog={handlemodal}
          open={openDialog}
          component="sidebar"
        />
      ) : null}
      <div className="">
        <QuestionCircleOutlined
          style={{ fontSize: 36 }}
          onClick={() => setOpenDialog(true)}
        />
      </div>
    </>
  );
};

export default FloatingIcon;
