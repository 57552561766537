/* eslint-disable no-unused-vars */
import axios from 'axios';
import Utils from '../../redux/utils';
import { baseURL } from '../../config/constant';

// const getHelpResponse = async (query) => {
//   try {
//     const apiOptions = {
//       endpoint: 'https://help.sadaynaal.com/answer/',
//       headers: {
//         Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2FkbWluLnNhZGF5bmFhbC5jb20vYXBpL2xvZ2luIiwiaWF0IjoxNzM4NTkyMTIzLCJleHAiOjE3Mzg2Nzg1MjMsIm5iZiI6MTczODU5MjEyMywianRpIjoiRDd6bjVEU2ZvdFRpRzZRYiIsInN1YiI6IjYzNyIsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.dmir3JNqKUsplK9PuE9REWFtQlP32VDNqDByWCb9Uxc`,
//       },
//       method: 'POST',
//       body: JSON.stringify({ query }),
//     };

//     const apiResponse = await Utils.CallApi(apiOptions);
//     return apiResponse.data;
//   } catch (error) {
//     console.error('Error fetching help response:', error);
//     throw error;
//   }
// };

const getHelpResponse = async (query) => {
  let data = JSON.stringify({ query });
  try {
    const response = await axios.post(
      'https://help.sadaynaal.com/answer/',
      data,
      {
        headers: {
          maxBodyLength: Infinity,
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Utils.getCurrentToken()}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching help response:', error);
    throw error;
  }
  // let data = JSON.stringify({ query });
  // let config = {
  //   method: 'post',
  //   maxBodyLength: Infinity,
  //   url: 'https://help.sadaynaal.com/answer/',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: `Bearer ${Utils.getCurrentToken()}`,
  //   },
  //   data: data,
  // };
  // axios
  //   .request(config)
  //   .then((response) => {
  //     return JSON.stringify(response.data);
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //   });
};

const HelpApi = {
  getHelpResponse,
};

export default HelpApi;
