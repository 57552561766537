/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  PaginationProps,
  Spin,
  Dropdown,
  Space,
  Tooltip,
} from 'antd';
import type { MenuProps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
  Page,
  Pagination,
  Breadcrumb,
  Button,
  ListCount,
  SearchBox,
  UserListDialog,
  UserList,
  DeleteConfirmation,
} from '../../components';
import UsersApi from '../../redux/middleware/users';
import { sortNumber, sortedData } from '../../helper/sortData';
import More from '../../assets/images/More.png';
import Edit from '../../assets/images/Edit.png';
import Delete from '../../assets/images/Delete.svg';
import DownRed from '../../assets/images/DownRed.png';
import DownGreen from '../../assets/images/DownGreen.png';
import Company from '../../assets/images/Company.png';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import { useSelector } from 'react-redux';
import Utils from '../../redux/utils';
import Eye from '../../assets/images/Eye.svg';
import { checkAuthentication } from '../../helper/checkAuthentication';
import { useTranslation } from 'react-i18next';

const PageDataLength = [6, 10, 20, 50];

const UserListView = () => {
  const user = Utils.getCurrentUser();
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [ascend, setAscend] = useState(true);
  const [dataLength, setDataLength] = useState(50);
  const [total, setTotal] = useState(0);
  const [userLoader, setUserLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [userCount, setUserCount] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [permissionStatus, setPermissionStatus] = useState(0);
  const navigate = useNavigate();
  const [selectedUserId, setSelectedUserId] = useState('');
  const [id, setid] = useState();
  const [dialogData, setDialogData] = useState({});
  const [search, setSearch] = useState('');
  const { currentBrand, currentShop } = useSelector((state: any) => state.Auth);
  const [allowRequest, setAllowRequest] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [currentBrandId] = useState(currentBrand?.brand_id);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { t } = useTranslation('translation', {
    keyPrefix: 'users',
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div className="dotOption modifierDropdownLabels">
          {t('dropdown_view')}
        </div>
      ),
      icon: <img src={Eye} width={15} />,
    },
    {
      key: '2',
      label: (
        <div className="dotOption modifierDropdownLabels">
          {t('dropdown_edit')}
        </div>
      ),
      icon: <img src={Edit} width={15} />,
    },
    {
      type: 'divider',
    },
    {
      key: '3',
      label: <div className="dotOption deleteText">Delete</div>,
      icon: <img src={Delete} width={15} />,
    },
  ];

  useEffect(() => {
    if (currentBrandId !== currentBrand?.brand_id) {
      setKeyword('');
      setAllowRequest(true);
    }
  }, [currentBrand, currentShop]);

  useEffect(() => {
    if (allowRequest) {
      pageData();
      setAllowRequest(false);
    }
  }, [allowRequest]);
  const items1: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div
          className="dotOption"
          onClick={(e) => {
            const status = 'Active';

            handlestatus(id, status);
          }}
        >
          {t('active_option')}
        </div>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: '2',
      label: (
        <div
          className="dotOption deleteText"
          onClick={() => {
            const status = 'Non-active';
            handlestatus(id, status);
          }}
        >
          {t('non_active')}
        </div>
      ),
    },
  ];
  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascend, sortData, column)
        : sortNumber(ascend, sortData, column);

    setSortData(() => arr);
  };

  const handlestatus = (id: any, key: string) => {
    if (key === 'Active') {
      UsersApi.disableUser(id, '1');
      pageData();
    } else {
      UsersApi.disableUser(id, '0');
      pageData();
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const userColumns: any = [
    {
      title: () => (
        <div className="tableTitle">
          <span>{t('id')}</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('id', 'number');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'id',
      key: 'id',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return <div className="id">{record?.id}</div>;
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>{t('name_label')}</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('name', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="font">
          <div
            className="user_name table-title"
            onClick={() => getUserDetails(record?.id)}
          >
            <Tooltip
              title={
                <div>
                  <div className="tootipText">{record?.name}</div>
                </div>
              }
            >
              {record?.name?.substring(0, 22)}
              {record?.name?.length > 22 ? <span>...</span> : null}
            </Tooltip>
          </div>
          <div className="email">{record?.email}</div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>
            {' '}
            {user?.roles[0]?.id === 3 ? t('branch_label') : t('company_label')}
          </span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('company_name', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'company_name',
      key: 'company_name',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="companyContainer">
          <img src={Company} />
          <div className="company">
            {record?.company_name?.length > 22 ? (
              <Tooltip
                title={
                  <div>
                    <div className="tootipText">
                      {user?.roles[0]?.id === 3
                        ? record?.shop_id[0]?.shop_name
                        : record?.company_name}
                    </div>
                  </div>
                }
              >
                {user?.roles[0]?.id === 3
                  ? record?.shop_id[0]?.shop_name.substring(0, 22)
                  : record?.company_name.substring(0, 22)}
                {user?.roles[0]?.id === 3 ? (
                  record?.shop_id[0]?.length > 22 ? (
                    <span>...</span>
                  ) : null
                ) : record?.company_name?.length > 22 ? (
                  <span>...</span>
                ) : null}
              </Tooltip>
            ) : user?.roles[0]?.id === 3 ? (
              <span>
                {record?.shop_id[0]?.shop_name.substring(0, 22)}{' '}
                {record?.shop_id[0]?.is_ck == 1 && (
                  <sub className="subscript-tag"> ( CK )</sub>
                )}
              </span>
            ) : (
              record?.company_name
            )}
          </div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>{t('user_role')}</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('roles', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'roles',
      key: 'roles',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="company">
            {record.roles.map((data: any) => data?.name).toString()}
          </div>
        );
      },
    },
    user?.roles[0]?.id !== 1 && {
      title: () => (
        <div className="tableTitle">
          <span>{t('brand_count')}</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('roles', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'roles',
      key: 'roles',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="company">
            <Tooltip
              title={
                <div>
                  <div className="tootipText">
                    {record.company_tree[0]?.brands
                      ?.map((brand: any) => ' ' + brand?.brand_name)
                      ?.toString()}
                  </div>
                </div>
              }
              className="cursor"
            >
              {record.company_tree[0]?.brands?.length}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>{t('status')}</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('status', 'number');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'status',
      key: 'status',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          overlayStyle={{ height: '100px' }}
          menu={menuDropdownProps}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a
            onClick={(e) => {
              setid(record.id);
            }}
          >
            <Space>
              <div
                className={
                  record?.status === 1
                    ? 'statusContainerActive'
                    : 'statusContainerNonActive'
                }
              >
                <div className="status">
                  {record?.status == 1 ? t('active_option') : t('non_active')}
                </div>
                <img src={record?.status === 1 ? DownGreen : DownRed} alt="" />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          overlayStyle={{ height: '100px' }}
          menu={DropDownmenuProps}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a onClick={() => setSelectedMenu(record)}>
            <Space>
              <div className="dots">
                <img src={More} />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ].filter(Boolean);
  useEffect(() => {
    checkAuthentication(user, navigate);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current, currentBrand]);

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const setSelectedMenu = (user: any) => {
    setSelectedUserId(user.id);
  };

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };
  useEffect(() => {
    let permissions = user?.permissions;
    const data = permissions?.filter(
      (permission: any) => permission?.permission === 'Users'
    );
    data?.length && setPermissionStatus(data[0]?.perm_status_id);
    setCurrent(1);
  }, []);

  // const handleMenuDropdown: MenuProps['onClick'] = (e: any) => {
  //   setDataLength(PageDataLength[+e?.key]);
  // };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const menuDropdownProps = {
    items: items1,
    // onClick: handleMenuDropdown,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const getUserDetails = async (id: any) => {
    setLoader(true);
    const data = await UsersApi.getUserById(id);
    setLoader(false);
    setDialogData(data?.data);
    setOpenDialog(true);
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 1: {
        getUserDetails(selectedUserId);
        break;
      }
      case 2:
        navigate('wizard', { state: { update: true, id: selectedUserId } });
        break;
      case 3:
        setOpenDeleteDialog(true);
        break;
      default:
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };
  const handleDeleteContinue = async () => {
    setOpenDeleteDialog(false);
    await UsersApi.deleteUser(selectedUserId).then(() => pageData());
    // selectedUserId.length && (await UsersApi.deleteUser(selectedUserId));
    // pageData();
  };

  const DropDownmenuProps = {
    items: permissionStatus && permissionStatus === 2 ? items : [],
    onClick: handleDropDownClick,
  };

  const actionButton = (
    <Button
      text={t('add_new')}
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={() => {
        navigate('wizard', { state: { update: false, id: '' } });
      }}
      shape="round"
      size="middle"
    />
  );

  const pageData = async () => {
    setUserLoader(true);
    const shop = await Utils.getCurrentShop();
    const brand = await Utils.getCurrentBrand();
    const user = await Utils.getCurrentUser();
    const userData = await UsersApi.getUsers(
      dataLength,
      current,
      user?.roles[0]?.id === 1 ? '' : brand?.brand_id || currentBrand?.brand_id,
      keyword
    );
    if (userData === undefined || !userData?.success) {
      setUserCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setUserCount(userData?.data?.users_count);
      setTotal(userData?.data?.pagination?.total);
      setSortData(userData?.data?.items);
    }
    setUserLoader(false);
  };

  const onSearch = async (keyword: string) => {
    setSearch(keyword);
    setUserLoader(true);
    const searchResultofRecipes = await UsersApi.searchUsers(
      dataLength,
      current,
      keyword
    );
    if (
      searchResultofRecipes === undefined ||
      !searchResultofRecipes?.success
    ) {
      setUserCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setUserCount(searchResultofRecipes?.data?.users_count);
      setTotal(searchResultofRecipes?.data?.pagination?.total);
      setSortData(searchResultofRecipes?.data?.items);
    }
    setUserLoader(false);
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading={t('users')}
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={actionButton}
        perm_status={permissionStatus}
      />
      <DeleteConfirmation
        handleDeleteCancel={handleDeleteCancel}
        handleDeleteContinue={handleDeleteContinue}
        open={openDeleteDialog}
        message={'Are you sure want to delete user?'}
      />
      <Spin spinning={loader} size="large">
        <Page title={t('users')}>
          <UserListDialog
            open={openDialog}
            handleClose={handleClose}
            data={dialogData}
          />
          <Row className="listingTopFilter">
            <Col lg={10} md={24}>
              <ListCount count1={userCount} title1={t('total_user')} />
            </Col>
            <Col lg={14} md={24}>
              <div className="search-wrapper">
                <div className="search-box">
                  <SearchBox
                    keyword={keyword}
                    setKeyword={setKeyword}
                    action={onSearch}
                    placeholderText={t('search_placeholder')}
                  />
                </div>
                {/* <div className="exportButton">
                <img src={Export} alt="" />
                <div className="exportText"> Export</div>
              </div>
              */}
              </div>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col lg={24} md={24}>
              {!userLoader ? (
                <UserList
                  dataSource={[...sortData]}
                  userColumns={userColumns}
                  limit={dataLength}
                />
              ) : (
                // <ListViewWithoutExpand
                //   items={items}
                //   dataSource={[...sortData]}
                //   columns={columns}
                // />
                <div className="loader-wrapper">
                  <Spin
                    spinning={userLoader}
                    className="recipeLoader"
                    size="large"
                  />
                </div>
              )}
              <Pagination
                total={total}
                menuProps={menuProps}
                onChange={onChange}
                current={current}
                dataLength={dataLength >= total ? total : dataLength}
                handleLength={(data: number) => setDataLength(data)}
              />
            </Col>
          </Row>
        </Page>
      </Spin>
    </React.Fragment>
  );
};

export default UserListView;
